import axios from "axios";
import Links from "@/shared/constants/Links";




const StudentProgress = {
  /**
   * fetch all student progress record
   * @param {string} matric matric of the student(userid)
   * @returns {Promise<any[]>}
   */
  fetchRecords: async (matric) => {
    return axios.get(Links.studentProgress.fetch + "?student=" + matric);
  },

  /**
   *
   * @param {string} studentMatric  Student's userId
   * @param {number} sessionId  session Id
   * @param {number} levelId  level ID
   * @param programmeId {number} programme ID
   * @param {date} date  date as string
   * @returns {Promise<AxiosResponse<any>>}
   */
  create: async (studentMatric, sessionId, levelId, programmeId, date, skipRegistration, skipLevel) => {
    return axios.post(Links.studentProgress.create, {
      student: studentMatric,
      session: sessionId,
      level: levelId,
      programme: programmeId,
      date: date,
      skipRegistration,
      skipLevel
    });
  },

  /**
   *
   * @param {string} studentMatric  Student's userId
   * @param {number} sessionId  session Id
   * @param {number} levelId  level ID
   * @param {number} programmeId  programme ID
   * @param {number} id  progress information ID
   * @param {date} date  date as string
   * @returns {Promise<AxiosResponse<any>>}
   */
  update: async (studentMatric, sessionId, levelId, programmeId, date, skipRegistration, skipLevel, id) => {
    return axios.put(Links.studentProgress.update + "/" + id, {
      student: studentMatric,
      session: sessionId,
      level: levelId,
      programme: programmeId,
      date: date,
      skipRegistration,
      skipLevel
    });
  },

  /**
   * @param {String} student id
   * @param {String} id
   * @returns {Promise<AxiosResponse<any>>}
   */
  delete: async (id) => {
    return axios.delete(Links.studentProgress.delete + "/" + id);
  },
};

export default StudentProgress;
