import axios from "axios";

const getBase = () => "admin";

const addAdmin = async (data) => {
    return axios.post(getBase(), data);
}

const updateAdmin = async (data, id) => {
    return axios.put(getBase() + "/" + id, data);
}

const deleteAdmin = async (id) => {
    return axios.delete(getBase() + "/" + id);
}

const getAllAdmins = async (page, size) => {
    return axios.get(getBase() + "?page=" + page + "&size=" + size);
}


const adminApi = {
    addAdmin,
    updateAdmin,
    deleteAdmin,
    getAllAdmins
}

export default adminApi;