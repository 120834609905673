import axios from "axios";

const getBaseUrl = () => "registered-courses";

const courseRegistrations = {
    /**
     * Fetches a list of registrations for approval based on the provided criteria.
     *
     * @async
     * @function
     * @param {number} course - The course identifier to filter registrations.
     * @param {number} semester - The semester to filter registrations.
     * @param {number} stage - The stage or phase of registration to filter.
     * @param {number} page - The page number for pagination.
     * @param {number} size - The number of registrations to fetch per page.
     * @param {string|null} [student=null] - Optional; The student identifier to filter registrations.
     * @param {string|null} [status=null] - Optional; The status of registrations to filter.
     * @param {string|null} [level=null] - Optional; The level of registration to filter.
     * @returns {Promise<AxiosResponse>} A promise that resolves to the response from the API containing the registrations.
     */
    getRegistrationsForApproval: async (course, semester, stage, page, size, student = null, status = null, level =  null) => {
        return axios.get(
            `${getBaseUrl()}/find-all-for-approval`,
            {
                params: {
                    course,
                    semester,
                    stage,
                    page,
                    size,
                    student,
                    status,
                    level
                }
            })
    },

    /**
     * Approves a list of registrations by sending their IDs to the appropriate endpoint.
     *
     * Makes a POST request to the `/approve` endpoint with an array of registration IDs.
     * This function is asynchronous and will return a promise that resolves to the response of the POST request.
     *
     * @param {Array<string>} registrationIds - An array of registration ID strings to approve.
     * @returns {Promise<Object>} A promise that resolves to the response from the server.
     */
    approveRegistrations: async (registrationIds) => {
        return axios.post(`${getBaseUrl()}/approve`, {
            registrationIds: registrationIds
        })
    },

    /**
     * Approves registrations for a specific stage.
     *
     * @param {string} stageId - The ID of the stage to approve registrations for.
     * @returns {Promise<Object>} A promise that resolves to the response from the server.
     */
    approveByStage: async (stageId) => {
        return axios.post(`${getBaseUrl()}/approve-by-stage`, {
            stageId: stageId
        })
    },

    /**
     * Rejects registrations for a specific stage.
     *
     * @param {string} stageId - The ID of the stage to reject registrations for.
     * @returns {Promise<Object>} A promise that resolves to the response from the server.
     */
    rejectByStage: async (stageId) => {
        return axios.post(`${getBaseUrl()}/reject-by-stage`, {
            stageId: stageId
        })
    },

    /**
     * Sends a POST request to reject specific registrations.
     *
     * This function takes an array of registration IDs and sends them to the
     * API endpoint for rejection. It uses Axios to make the HTTP POST request,
     * with the body containing the provided registration IDs.
     *
     * @async
     * @function
     * @param {Array<string>} registrationIds - An array of registration IDs to be rejected.
     * @returns {Promise<Object>} A promise that resolves to the response object from the server.
     */
    rejectRegistrations: async (registrationIds) => {
        return axios.post(`${getBaseUrl()}/reject`, {
            registrationIds: registrationIds
        })
    },


    /**
     * Fetches historical data based on the specified criteria.
     *
     * @param {string} course - The course identifier for which the history is requested.
     * @param {string} semester - The semester for which the history is requested.
     * @param {number} page - The page number for paginated results.
     * @param {number} size - The number of items per page for paginated results.
     * @param {string|null} [student=null] - Optional identifier of the student to filter the results.
     * @returns {Promise} - A promise that resolves to the response from the API containing the historical data.
     */
    history: async (course, semester, page, size, student = null) => {
        return axios.get(
            `${getBaseUrl()}/history`,
            {
                params: {
                    course,
                    semester,
                    student,
                    page,
                    size,
                }
            }
        )
    },

}
export default courseRegistrations
