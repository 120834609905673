<template>
  <div class="" style="overflow-x: hidden">
    <v-row class="py-0 my-0" justify="space-between" style="height: 100vh; overflow-x: hidden">
      <v-col sm="4" cols="12" md="4" style="background-color: #57430E; color: white; height: 100%; min-height: 50%;" class="pr-0 py-0 my-0 r-side"
        v-if="$vuetify.breakpoint.mdAndUp">
        <v-container style="height: 100%;" class="d-flex flex-column pr-0 r-side-overlay">
          <div v-if="isMobile" class="white--text fade-in">
            <h2 class="university-title">Federal University of Lafia</h2>
            <p class="university-motto">Integrity, Innovation, Excellence</p>
          </div>

          <div v-else class="text-left ml-md-6 white--text fade-in">
            <h5 class="university-title pb-1 font-weight-bold">Federal University of Lafia</h5>
            <p class="university-motto">Integrity, Innovation, Excellence</p>
          </div>

          <v-spacer></v-spacer>

          <div class="control-tabs d-flex flex-column align-content-end" style="margin-top: -20%;">
            <v-tabs v-model="tab" background-color="transparent" style="background-color: transparent" grow vertical
                    hide-slider class="pa-0 ma-0 ml-auto">
              <v-tab :id="item.value" v-for="item in baseComponents" @click="() => { currentTab = item.children; currentTabValue = item.value }" aria-label="tab" :key="item.value"
                style="width: 100px; margin-right: 50px; border-top-left-radius: 8px; border-bottom-left-radius: 8px"
                :class="[currentTabValue === item.value ? 'white primaryDark--text md-mt-3 mt-0 pa-0 px-8 ma-0 ml-auto text-left tab-active' : 'transparent white--text pa-0 ma-0 px-8 md-mt-3 mt-0 ml-auto text-left tab-inactive']">
                {{ item.title }}
              </v-tab>
            </v-tabs>
          </div>

          <v-spacer></v-spacer>
          <div class="logo-container text-left" style="text-align: left !important">
            <v-img class="logo ml-0 logo-hover" contain width="100" height="100" :src="logo" />
          </div>
        </v-container>
      </v-col>

      <v-col cols="12" class="d-flex flex-column pt-0 pb-0 mb-0 r-side" v-else
        style="background-color: #57430E; color: white; height: 25%; position: relative">
        <div class="r-side-overlay pb-0 mb-0 d-flex flex-column" style="height: 100%">
          <div class="text-center white--text fade-in" style="margin-top: 10%;">
            <h3 class="university-title">Federal University of Lafia</h3>
            <p class="university-motto">Integrity, Innovation, Excellence</p>
          </div>

          <div class="mt-auto" style="width: 100%">
            <v-tabs v-model="tab" background-color="transparent" style="color: white;" centered dark icons-and-text
                     hide-slider height="40">
              <v-tab :id="item.value" v-model="tab" v-for="item in baseComponents" :key="item.value"
                     @click="() => { currentTab = item.children; currentTabValue = item.value }" aria-label="tab"
                     style="border-top-left-radius: 8px; border-top-right-radius: 8px"
                     :class="[currentTabValue === item.value ? 'white primaryDark--text px-10 tab-active' : 'transparent white--text px-10 tab-inactive']">
                {{ item.title }}
              </v-tab>
            </v-tabs>
          </div>
        </div>
      </v-col>

      <v-col class="mb-10 mb-md-4 right-panel d-flex flex-column align-center" sm="12" cols="12" md="8">
        <div class="right-panel-content">
          <h4 class="text-center font-weight-bold mt-4 page-title" v-if="$vuetify.breakpoint.smAndDown">{{ currentRoute }}</h4>
          <div class="text-center name-tag font-weight-bold mb-4 page-title" v-else>
            {{ currentRoute }}</div>
          <transition name="route" mode="out-in">
            <component :logo="logo" :is="currentTab" :record="record" @update-record="v => record = v" @navigate="val => currentTab = tabs[val]" />
          </transition>
        </div>
      </v-col>
    </v-row>
    <announcement :show="show" :announcements="announcements" />
  </div>
</template>

<script>
import Defaults from 'shared/Defaults';
import Links from 'shared/constants/Links';
import AppTypes from 'shared/constants/apptypes';
import ErrorResolver from 'shared/ErrorResolver';
import Signup from './Signup.vue';
import Login from './Login.vue'
import ForgotPassword from "./ForgotPassword.vue";
import OTP from "./OTP.vue";
import ResetPassword from "./ResetPassword.vue";
import Api from 'main/api';
import Announcement from "@ui/components/Announcement.vue";

export default {
  name: "AuthLafia",
  components: {
    Announcement,
    Signup,
    Login
  },
  data: () => ({
    show: false,
    currentTab: 'login',
    logo: '',
    tab: null,
    currentTabValue: 'login',
    baseComponents: [
      { id: 1, title: 'Login', value: 'login', children: Login},
      { id: 2, title: 'Apply', value: 'apply', children: Signup}
    ],
    tabs: [],
    announcements: [],
    record: '' // shared record across all components
  }),
  computed: {
    currentComponent() {
      return this.currentRoute;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    currentRoute() {
      return ''
    },
  },
  methods: {
    fetchGlobal() {
      if (AppTypes.CURRENT === AppTypes.LAFIA) {
        Api.global.fetch().then(() => {
          this.logo = Defaults.api + Links.global.logo;
        }).catch((err) => ErrorResolver.checkErrors(this, err))
      }
    },

    loadAnnouncements(){
      Api.annoucement.fetchPublic()
        .then(res => {
          if (res.data) {
            this.announcements = res.data;
            this.show = (this.announcements.length > 0);
          }
        })
    }
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    let signup = urlParams.get('apply');
    if(signup){
      this.currentTab = this.baseComponents[1].children;
      this.currentTabValue = this.baseComponents[1].value;
    }
    else{
      this.currentTabValue = 'login';
    }
    this.fetchGlobal();
  },
  created() {
    this.tabs.push(Login);
    this.tabs.push(ForgotPassword);
    this.tabs.push(OTP);
    this.tabs.push(ResetPassword);
    this.loadAnnouncements()
  },
}
</script>

<style scoped lang="scss">
.r-side {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../resources/lafia-bg.jpg");
  transition: all 0.3s ease;
}

.r-side-overlay {
  background-color: rgba(87, 67, 14, 0.85);
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
}

.name-tag {
  position: absolute;
  width: 65%;
  top: 35px;
  text-align: center;
  color: #57430E;
  align-items: center;
}

.logo-container {
  position: relative;
  bottom: 2%;
  // display: flex;
  align-items: flex-start;
  width: 100%;
}

.logo {
  opacity: 1;
  transition: transform 0.3s ease;
}

.logo-hover:hover {
  transform: scale(1.05);
}

.v-card {
  border: 2px solid #AE8B31;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.university-title {
  font-size: 1.65em;
  letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.university-motto {
  color: rgba(255, 255, 255, 0.9) !important;
  font-size: 1.1em;
  letter-spacing: 1px;
  font-style: italic;
}

.tab-active {
  transition: all 0.3s ease;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab-inactive {
  transition: all 0.3s ease;
  opacity: 0.9;

  &:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.right-panel {
  background-color: #fff;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 1rem;
}

.right-panel-content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
  
  @media (max-width: 959px) {
    padding: 0;
  }
}

.page-title {
  color: #57430E;
  opacity: 0.85;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
}

.fade-in {
  animation: fadeIn 0.8s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.route-enter-active,
.route-leave-active {
  transition: opacity 0.3s ease;
}

.route-enter-from,
.route-leave-to {
  opacity: 0;
}
</style>
