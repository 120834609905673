import Defaults from "@/shared/Defaults";

/**
 * Utility class for working with URLs
 */
const urlUtil = {
    /**
     * 
     * @param {String} url 
     * @returns {String} The query string part of the URL
     */
    getQuery(url) {
        const query = url.split('?')[1];
        return query;
    },

    getBaseUrl() {
        if(location.host.includes('localhost'))
            return Defaults.api

        Defaults.api = (location.host.startsWith('http') ? location.host : "https://" + location.host) + "/api"
        return Defaults.api
    },
};

export default urlUtil;
