import axios from "axios";
import Links from "../constants/Links";

const registrationStageApproval = {
  /**
   * Create an approval
   * @param {*} data
   * @returns
   */
  create: async (data) => {
    return axios.post(Links.registrationApproval.create, data)
  },

  /**
   * Delete an approval
   * @param {*} id
   * @returns
   */
  delete: async (id) => {
    return axios.delete(Links.registrationApproval.create + '/' + id)
  },

  /**
   * Update an approval
   * @param {*} data
   * @returns
   */
  update: async (id, data) => {
    return axios.put(Links.registrationApproval.create + '/' + id, data)
  },

  /**
   * Get stages
   * @returns
   */
  getStages: async () => {
    return axios.get(Links.registrationApproval.create)
  },

  /**
   * Get stage users
   * @returns
   */
  getStageUser: async (programmeTypeId) => {
    return axios.get(Links.registrationApproval.create + '/user?programme_type=' + programmeTypeId)
  }
};

export default registrationStageApproval;
