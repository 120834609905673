import axios from "axios";
import Links from "../constants/Links";


/**
 * This is the Lecturer API hook, for handling Lecturer (C) 2021-2023
 */

const Lecturer = {
    /**
     * @description Creates a staff
     * @param {object} data an object conatining the following: titleId, userId, firstName, lastName, middleName(optional), email,
     * departmentId(Optional. only is as academic staff is selected), academic(true or false)
     * departments(array of IDs of the departments the user will manage), empty list can be provided,
     * faculties(array of IDs of the faculties the user will manage), empty list can be provided
     * roles(arrays of values of roles)
     * @returns {Promise<object>} the newly created staff
     */
    create: async (data) => {
        return axios.post(Links.lecturers.create, data)
    },

    /**
     * make a staff to be a faculty officer
     * @param {string} staffId staff id
     * @returns {Promise<object>}
     */
    makeFacOfficer: async (staffId) => {
        return axios.put(Links.lecturers.makeFacOfficer + staffId)
    },

    /**
     * make a department staff
     * @param {string} staffId staff id
     * @param {object} data information of the staff
     * @returns {Promise<object>}
     */
    makeDepOfficer: async (staffId, data) => {
        return axios.put(Links.lecturers.makeDepOfficer + staffId, data)
    },

    /**
     * make a faculty dean
     * @param {string} staffId
     * @returns {Promise<object>}
     */
    makeFacDean: async (staffId) => {
        return axios.put(Links.lecturers.makeFacDean + staffId)
    },

    /**
     * @description Updates a staff
     * @param {object} data an object conatining the following: titleId, userId, firstName, lastName, middleName(optional), email,
     * departmentId(Optional. only is as academic staff is selected), academic(true or false),
     * * departments(array of IDs of the departments the user will manage), empty list can be provided,
     * faculties(array of IDs of the faculties the user will manage), empty list can be provided
     * roles(arrays of values of roles)
     * @returns {Promise<object>} the updated staff
     */
    updateFromAdmin: async (id, data) => {
        return axios.put(Links.lecturers.updateFromAdmin + '/' + id, data);
    },

    /**
     * update staff details
     * @param {object} data
     * @returns {Promise<object>}
     */
    update: async (data) => {
        return axios.put(Links.lecturers.update, data);
    },

    /**
     * fetch all staff in the system
     * @returns {Promise<any[]>}
     */
    all: async () => {
        return axios.get(Links.lecturers.all)
    },

    /**
     * fetch all staff in the system (paginated)
     * @param {number} page
     * @param {number} size
     * @param {number} departmentId departmental id
     * @param {string} role
     * @returns {Promise<any[]>}
     */
    allPaginated: async (page, size, departmentId, role) => {
        let url = Links.lecturers.allPaginated + `?page=${page}&size=${size}`;
        if(departmentId !== null){
            url = url + `&departmentId=${departmentId}`;
        }
        if(role){
            url = url + `&role=${role}`;
        }
        return axios.get(url)
    },

    /**
     * fetch all faculty staffs specifically
     * @param {string} id faculty id
     * @returns {Promise<any[]>}
     */
    allFacultyLecturers: async (id) => {
        return axios.get(Links.lecturers.allFaculty + id)
    },

    /**
     * fetch all departmental lecturers
     * @param {string} id department id
     * @returns {Promise<any[]>}
     */
    allDepartmentLecturers: async (id) => {
        return axios.get(Links.lecturers.allDepartment + id)
    },

    /**
     * fetch all staff's
     * @returns {Promise<any[]>}
     */
    allWithOfficers: async () => {
        return axios.get(Links.lecturers.allWithOfficers)
    },

    /**
     * fetch courses for the staff base on programmetype and its userId
     * @param {string} programmeType programmeType id
     * @param {string} staffId staff id
     * @returns {Promise<object>}
     */
    getCourses: async (programmeType, staffId) => {
        return axios.get(Links.lecturers.getCourses + programmeType + '/' + staffId)
    },

    /**
     * fetch courses having approval status
     * @param {string} programmeType programmeType if
     * @param {string} sessionId session id
     * @param {string} semester semester id
     * @param {string} staffId semester id
     * @returns {Promise<object>}
     */
    getCoursesWithApprovalStatus: async(programmeType, sessionId, semester, staffId) => {
        return axios.get(Links.lecturers.getCoursesWithApprovalStatus + programmeType + '/' + sessionId + '/' + semester + '/' + staffId)
    },

    /**
     * assign a staff to be HOD
     * @param {string} staffId staff id
     * @returns {Promise<boolean>}
     */
    makeHod: async (staffId) => {
        return axios.put(Links.lecturers.makeHod + staffId)
    },

    /**
     * un-assign staff not be HOD
     * @param {string} staffId
     * @returns {Promise<boolean>}
     */
    unmakeHod: async (staffId) => {
        return axios.put(Links.lecturers.unmakeHod + staffId)
    },

    /**
     * fetch all senates individuals
     * @returns {Promise<any[]>}
     */
    allSenates: async () => {
        return axios.get(Links.lecturers.allSenates)
    },

    /**
     * assign to be a senate member
     * @param {string} staffId
     * @returns {Promise<boolean>}
     */
    makeSenate: async (staffId) => {
        return axios.put(Links.lecturers.makeSenate + staffId)
    },

    /**
     * un-assign to be a senate member
     * @param {string} staffId
     * @returns {Promise<boolean>}
     */
    unmakeSenate: async (staffId) => {
        return axios.put(Links.lecturers.unmakeSenate + staffId)
    },

    /**
     * assign to be Vice chancellor(V.C)
     * @param {string} staffId staff id
     * @returns {Promise<object>}
     */
    makeVc: async (staffId) => {
        return axios.put(Links.lecturers.makeVc + staffId)
    },

    /**
     * assign to be DVC academics
     * @param {string} staffId
     * @returns {Promise<boolean>}
     */
    makeDVCAcademics: async (staffId) => {
        return axios.put(Links.lecturers.makeDVCAcademics + staffId)
    },

    /**
     * assign to be DVC administrations
     * @param {string} staffId
     * @returns {Promise<boolean>}
     */
    makeDVCAdministration: async (staffId) => {
        return axios.put(Links.lecturers.makeDVCAdministration + staffId)
    },

    /**
     * assign to be Bursar
     * @param {string} staffId
     * @returns {Promise<boolean>}
     */
    makeBursar: async (staffId) => {
        return axios.put(Links.lecturers.makeBursar + staffId)
    },

    /**
     * assign to be registerer
     * @param {string} staffId
     * @returns {Promise<boolean>}
     */
    makeRegisterer: async (staffId) => {
        return axios.put(Links.lecturers.makeRegisterer + staffId)
    },

    /**
     * assign to be Admin Secretary
     * @param {string} staffId
     * @returns {Promise<boolean>}
     */
    makeAcademicSecretary: async (staffId) => {
        return axios.put(Links.lecturers.makeAcademicSecretary + staffId)
    },

    /**
     * @description Makes a staff an hostel officer
     * @param {string} staffId The staff ID of ther staff to be made an hostel officer
     * @returns the modified instance of the staff
     */
    makeHostelOfficer: async (staffId) => {
        return axios.put(Links.lecturers.makeHostelOfficer + '/' + staffId)
    },

    /**
     * @description Makes a staff an student affair officer
     * @param {string} staffId The staff ID of ther staff to be made an student affair officer
     * @returns the modified instance of the staff
     */
    makeStudentAffairOfficer: async (staffId) => {
        return axios.put(Links.lecturers.makeStudentAffairOfficer + '/' + staffId)
    },

    /**
     * @description Makes a staff an admission officer
     * @param {string} staffId The staff ID of ther staff to be made an admission officer
     * @returns the modified instance of the staff
     */
    makeAdmissionOfficer: async (staffId) => {
        return axios.put(Links.lecturers.makeAdmissionOfficer + '/' + staffId)
    },

    /**
     * @description Makes a staff a biometric officer
     * @param {string} staffId The staff ID of ther staff to be made a biometric officer
     * @returns the modified instance of the staff
     */
     makeBiometricOfficer: async (staffId) => {
        return axios.put(Links.lecturers.makeBiometricOfficer + '/' + staffId)
    },

    /**
     * @description Removes a staff from list of biometric officers
     * @param {string} staffId The staff ID of ther staff to be removed from biometric officers
     * @returns the modified instance of the staff
     */
     removeBiometricOfficer: async (staffId) => {
        return axios.put(Links.lecturers.removeBiometricOfficer + '/' + staffId)
    },

    /**
     * @description searches for a staff by name or userID
     * @param {string} query name or ID
     * @returns {Promise<object>}
     */
    search: async(query) => {
        return axios.get(Links.lecturers.search + "?query=" + query)
    },

    getLevelAdvisers: async(department) => {
        return axios.get(Links.lecturers.getLevelAdvisers + "?department=" + department)
    },

    
}

export default Lecturer
