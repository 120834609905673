import axios from "axios";
import Links from "../constants/Links";

const adviser = {
  /**
   * levelId
   * @param {*} data 
   */
  makeLevel: (data) => {
    return axios.post(Links.adviser.makeLevel, {
      ...data
    })
  }, 
}; 

export default adviser;