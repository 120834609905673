import Vue from 'vue'
import Verification from './Verification.vue'
import vuetifyNsuk from "../plugins/vuetify";
import vuetifyLafia from "../plugins/lafiaPlugin";
import AppNsuk from "./Verification.vue";
import AppLafia from "./lafia/App.vue";
import router from './routes/index';
import store from "./store";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import AppTypes from "@/shared/constants/apptypes";
import Defaults from '@/shared/Defaults';
import axios from 'axios';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import vuetifyFpn from "@/plugins/fpnPlugin";
import urlUtil from 'utils/urlUtil';

// set default api
axios.defaults.baseURL = urlUtil.getBaseUrl();


let nsuk = AppTypes.CURRENT === AppTypes.NSUK;
let lafia = AppTypes.CURRENT === AppTypes.LAFIA;


Vue.config.productionTip = false;
if(nsuk)
    Vue.use(VueToast);
else
    Vue.use(Toast, {
        transition: "Vue-Toastification__fade",
        maxToasts: 20,
        newestOnTop: true
    })

new Vue({
    vuetify: nsuk ? vuetifyNsuk : lafia ? vuetifyLafia : vuetifyFpn,
  router,
  store,
  render: (h) => h(nsuk ? AppNsuk : AppLafia),
}).$mount("#app");
